import React, { useEffect, useState } from "react";
import TextField from '@material-ui/core/TextField';
import DateTimePicker from 'react-datetime-picker';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import firebase from "../../../database"
import CheckIcon from '@material-ui/icons/Check';
import { Navbar } from 'react-bootstrap'
import { server } from "../../../server"
import { CSVLink, CSVDownload } from "react-csv";




const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    table: {
        minWidth: 700,
    },
}));


function Vendors(props) {
    const classes = useStyles();
    const [value, setvalue] = useState(1)
    const [bank, setbank] = useState("Select Vendor")
    const [newbank, setnewbank] = useState([])
    const [bankdetail, setbankdetail] = useState('')
    const [BankIncomePayment, setBankIncomePayment] = useState('')
    const [BankExpensePayment, setBankExpensePayment] = useState('')

    const [bankladgerArray, setbankladgerArray] = useState(['daily Expense'])



    useEffect(() => {

        const admin = sessionStorage.getItem("adminerp")
        if (!admin) {
            props.history.push('/')
        }






        var bankladgers = [];
        firebase.database().ref('bankladger/' + bank).once("value", snap => {
            var data = snap.val();
            for (let keys in data) {
                bankladgers.push({ ...data[keys], key: keys });
            }
            setbankladgerArray(bankladgers)
        })



        var newbanks = [];
        firebase.database().ref('banks').once("value", snap => {
            var data = snap.val();
            for (let keys in data) {
                newbanks.push({ ...data[keys], key: keys });
            }
            setnewbank(newbanks)
        })



    }, [bank])










    const incomeAction = () => {
        var ladgerbankdata = {
            date: new Date().toLocaleDateString('de-DE'),
            bankdetail: bankdetail,
            bankincomepayment: BankIncomePayment,
            bank: bank,
            balance: ""
        }

        if (bank === "Select Vendor") {
            alert("select vendor first")

        }

        else if (bank === "") {
            alert("select vendor first")
        }

        else if (bankdetail === "") {
            alert("fill all fields")
        }

        else if (BankIncomePayment === "") {
            alert("fill all fields")
        }

        else {

            if (bankladgerArray.length === 0) {
                ladgerbankdata.balance = BankIncomePayment
            }
            else {
                var c = Number(bankladgerArray[bankladgerArray.length - 1].balance) + Number(ladgerbankdata.bankincomepayment)
                ladgerbankdata.balance = c
            }
            firebase
                .database()
                .ref('bankladger/' + bank)
                .push(ladgerbankdata)
                .then(response => {
                    alert("Successfully add in ladger")
                    window.location.href = `${server}/vendors`;
                    // setbank(bank)
                })
                .catch(error => {
                    alert(error)
                });

        }

    }

    const expenseAction = () => {
        var ladgerExpensebankdata = {
            date: new Date().toLocaleDateString('de-DE'),
            bankdetail: bankdetail,
            bankexpensepayment: BankExpensePayment,
            bank: bank,
            balance: ""
        }



        if (bank === "Select Vendor") {
            alert("select vendor first")

        }

        else if (bank === "") {
            alert("select vendor first")
        }

        else if (bankdetail === "") {
            alert("fill all fields")
        }

        else if (BankExpensePayment === "") {
            alert("fill all fields")
        }

        else {

            if (bankladgerArray.length === 0) {
                ladgerExpensebankdata.balance = BankExpensePayment
            }
            else {
                var c = Number(bankladgerArray[bankladgerArray.length - 1].balance) - Number(ladgerExpensebankdata.bankexpensepayment)
                ladgerExpensebankdata.balance = c
            }

            firebase
                .database()
                .ref('bankladger/' + bank)
                .push(ladgerExpensebankdata)
                .then(response => {
                    alert("Successfully add in ladger")
                    window.location.href = `${server}/vendors`;
                })
                .catch(error => {
                    alert(error)
                });

        }

    }




    return (
        <div className="showroomMain">

            <Navbar fixed="top" style={{ marginTop: "13%", flexDirection: "column", backgroundColor: "#424242" }} >
                <div className="selectiondivsmain">
                    <div onClick={() => setvalue(1)} style={{ borderBottom: value === 1 && "2px solid red" }}>Work</div>
                    <div onClick={() => setvalue(2)} style={{ borderBottom: value === 2 && "2px solid red" }}>Payment Given</div>
                    <div onClick={() => setvalue(3)} style={{ borderBottom: value === 3 && "2px solid red" }}>Ladger</div>
                </div>

                {value !== 0 &&

                    <div className="selectlistMain">
                        <div style={{ display: "flex", justifyContent: "center", color: "red", fontFamily: "italic", fontSize: "18px" }}>
                            {bank}
                        </div>
                        <select onChange={(event) => setbank(event.target.value)} className="selectlist">
                            <option value="Select Vendor">Select Vendor</option>
                            {newbank.map((item) => {
                                return (
                                    newbank &&
                                    <option value={item.addbank}>{item.addbank}</option>
                                )
                            })}
                        </select>
                    </div>

                }
            </Navbar>




            {value === 1 &&
                <div className="debitMain">
                    <div style={{ marginTop: "30px", display: "flex", justifyContent: "center" }}>
                        <TextField
                            onChange={(event) => setbankdetail(event.target.value)}
                            style={{ width: "90%" }}
                            id="filled-primary"
                            label="Detail"
                            variant="filled"
                            color="primary"
                        />

                    </div>
                    <div style={{ marginTop: "30px", display: "flex", justifyContent: "center" }}>
                        <TextField
                            onChange={(event) => setBankIncomePayment(event.target.value)}
                            style={{ width: "90%" }}
                            id="filled-primary"
                            label="Enter Payment"
                            variant="filled"
                            color="primary"
                            type="number"
                        />

                    </div>


                    <div className="saveButton">
                        <Button
                            onClick={incomeAction}
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}
                            startIcon={<SaveIcon />}
                        >
                            Save
                        </Button>
                    </div>

                </div>
            }

            {value === 2 &&
                <div className="debitMain">
                    <div style={{ marginTop: "30px", display: "flex", justifyContent: "center" }}>
                        <TextField
                            onChange={(event) => setbankdetail(event.target.value)}
                            style={{ width: "90%" }}
                            id="filled-primary"
                            label="Detail"
                            variant="filled"
                            color="primary"
                        />

                    </div>
                    <div style={{ marginTop: "30px", display: "flex", justifyContent: "center" }}>
                        <TextField
                            onChange={(event) => setBankExpensePayment(event.target.value)}
                            style={{ width: "90%" }}
                            id="filled-primary"
                            label="Enter Payment"
                            variant="filled"
                            color="primary"
                            type="number"
                        />

                    </div>


                    <div className="saveButton">
                        <Button
                            onClick={expenseAction}
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}
                            startIcon={<SaveIcon />}
                        >
                            Save
                        </Button>
                    </div>

                </div>
            }



            {value === 3 &&
                <div>
                    <div style={{ marginTop: "200px", display: "flex", justifyContent: "center", width: "100%" }}>
                        <div style={{ display: "flex", justifyContent: "center", backgroundColor: "grey", width: "50%", padding: "10px", borderRadius: "10px" }}>
                            <CSVLink style={{ color: "black", fontWeight: "bold" }} data={bankladgerArray}>Download Ladger</CSVLink>
                        </div>
                    </div>
                    {bankladgerArray.map((item, i) => {
                        return (
                            item.bank === bank && item.bank !== "Select bank" &&
                            <div className="ladgerMain">
                                <div >
                                    <div className="detail">Detail </div>
                                    <div className="detailinner">{item.bankdetail}</div>
                                </div>

                                <div className="ladgerRow2">

                                    <div>
                                        <div className="ladgerRow2Titles">Date </div>
                                        <div className="ladgerRow2detail">{item.date}</div>
                                    </div>

                                    <div>
                                        <div className="ladgerRow2Titles">Work</div>
                                        <div className="ladgerRow2detail">{item.bankincomepayment}</div>
                                    </div>

                                    <div>
                                        <div className="ladgerRow2Titles">Payment Given</div>
                                        <div className="ladgerRow2detail">{item.bankexpensepayment}</div>
                                    </div>

                                    <div>
                                        <div className="ladgerRow2Titles">Balance</div>
                                        <div className="ladgerRow2detail">{item.balance}</div>
                                    </div>
                                </div>
                            </div>

                        )
                    })
                    }
                </div>
            }


        </div>
    );
}

export default Vendors;
