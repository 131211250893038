import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
// import "./addshowroom.css"
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import firebase from "../../../database"
import {server} from "../../../server"

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
            display: "flex",
            justifyContent: "center",
            alignItem: "center"
        },
    },
    button: {
        margin: theme.spacing(1),
        width: '25ch',

    },
}));

function Addbank (props) {
    const classes = useStyles();

    const [addbank, setaddbank] = useState("");

    useEffect(() => {

        const admin = sessionStorage.getItem("adminerp")
        if (!admin) {
            props.history.push('/')
        }

    }, [])

    const uploadbank = () => {
        var data = {
            addbank: addbank,
          }
       
    if(addbank === ""){

        alert("enter name")
    }

    else{
        firebase
        .database()
        .ref('banks')
        .push(data)
        .then(response => {
          alert("vendor name successfully Add")
          window.location.href = `${server}/vendors`;

        })
        .catch(error => {
          alert(error)
        });
    }
          
          
    }

    return (
        <div style={{ marginTop: "30%", height: "500px"}}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <TextField
                    onChange={(e) => setaddbank(e.target.value)}
                    id="filled-primary"
                    label="Enter Vendor Name"
                    variant="filled"
                    color="primary"
                />

            </div>

            <div style={{display:"flex",justifyContent:"center"}}>
                <Button
                      onClick={uploadbank}
                    style={{ marginLeft: "5px" }}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    endIcon={<CloudUploadIcon>Add Bank</CloudUploadIcon>}
                >
                    Add Vendor Name
            </Button>
            </div>

        </div>

    );
}

export default Addbank;
