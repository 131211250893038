import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import "./addshowroom.css"
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import firebase from "../../../database"
import { server } from "../../../server"

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
            display: "flex",
            justifyContent: "center",
            alignItem: "center"
        },
    },
    button: {
        margin: theme.spacing(1),
        width: '25ch',

    },
}));

function AddShowroom(props) {
    const classes = useStyles();

    const [addshowroom, setaddshowroom] = useState("");

    useEffect(() => {

        const admin = sessionStorage.getItem("adminerp")
        if (!admin) {
            props.history.push('/')
        }

    }, [])

    const uploadshowroom = () => {
        var data = {
            addshowroom: addshowroom,
        }


        if (addshowroom === '') {
            alert("Enter Showroom name")
        }

        else {
            firebase
                .database()
                .ref('showrooms')
                .push(data)
                .then(response => {
                    alert("Showroom successfully Add")
                    window.location.href = `${server}/showroom`;

                })
                .catch(error => {
                    alert(error)
                });

        }


    }

    return (
        <div style={{ marginTop: "30%", height: "500px" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <TextField
                    onChange={(e) => setaddshowroom(e.target.value)}
                    id="filled-primary"
                    label="Enter Showroom Name"
                    variant="filled"
                    color="primary"
                />

            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                    onClick={uploadshowroom}
                    style={{ marginLeft: "1px" }}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    endIcon={<CloudUploadIcon>Add Showroom</CloudUploadIcon>}
                >
                    Add Showroom
                </Button>
            </div>

        </div>

    );
}

export default AddShowroom;
