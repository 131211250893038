import React, { useState, useEffect } from "react";
import Showroom from "../Components/showrooms"
import Bank from "../Components/banks"

import Home from "../Components/Home"
import Navbar from "../Components/Navbar"
import Login from "../Components/login"
import AddShowroom from "../Components/showrooms/addshowroom"
import Addbank from "../Components/banks/addbank"
import Vendor from "../Components/banks/vendors"
import Footer from "../Components/Footer"
import createBrowserHistory from "history/createBrowserHistory";
import { Router, Route, Switch } from "react-router-dom";

const customHistory = createBrowserHistory();

function Routing() {
  const [user, setuser] = useState(false)

  useEffect(() => {
    const admin = sessionStorage.getItem("adminerp")
    if (admin) {
      setuser(true)
    }
  })

  return (
    <div>
      <Router history={customHistory}>
        {user &&
          <Navbar history={customHistory} />
        }
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/Home" component={Home} />
          <Route path="/showroom" component={Showroom} />
          <Route path="/expenseAndIncome" component={Bank} />
          <Route path="/addshowroom" component={AddShowroom} />
          {/* <Route path="/addvendor" component={Addbank} /> */}
          {/* <Route path="/vendors" component={Vendor} /> */}

        </Switch>
        {user &&
          <Footer />
        }

      </Router>
    </div>
  );
}
export default Routing;
