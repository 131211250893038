
import firebaseconfig from 'firebase'




// zubairkamboh49@gmail.com account
var config = {
  apiKey: "AIzaSyB19hkSn4B57Ujp3FCetEWeLNrw1SkGyvs",
  authDomain: "iconwood-erp-1618b.firebaseapp.com",
  databaseURL: "https://iconwood-erp-1618b-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "iconwood-erp-1618b",
  storageBucket: "iconwood-erp-1618b.appspot.com",
  messagingSenderId: "636110773111",
  appId: "1:636110773111:web:31719b835cadb7ff8cdf72",
  measurementId: "G-0WBXRRJ2W3"
};

// zubairkamboh49@gmail.com account




var firebase = firebaseconfig.initializeApp(config);
export {firebase as default }