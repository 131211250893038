import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import clsx from 'clsx';
import Logo from "../../assets/logo.gif"
import "./login.css"
import firebase from "../../database"
import {server} from "../../server"

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
      display: "flex",
      justifyContent: "center",
      alignItem: "center"
    },
  },
  button: {
    margin: theme.spacing(1),
    width: '25ch',

  },
}));


function Login(props) {
  const classes = useStyles();
  const [username, setusername] = useState("iconwoodofficial@gmail.com");
  const [password, setpassword] = useState("");
  
  const [values, setValues] = React.useState({
    showPassword: false,
  });

  useEffect(() => {

    const admin = sessionStorage.getItem("adminerp")
    if (admin) {
      props.history.push('/showroom')
    }
  }, [])
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const login = () => {
    var logindata = {
      username: username,
      password: password
    }
    // firebase
    //   .auth()
    //   .signInWithEmailAndPassword(username, password)
    //   .then(response => {
    //     var user = response.user.uid
    //     localStorage.setItem("admin", user)
    //     window.location.href = `${server}/addshowroom`;
    //   })
    //   .catch(error => {
    //     alert(error.message);
    //   });

      if(username === "iconwoodofficial@gmail.com" && password === "Bmw200zka1818"){
        sessionStorage.setItem('adminerp', JSON.stringify(logindata))
        window.location.href = `${server}/Home`;
       }
       else{
           alert("Invalid Login Details")
       }
  }

  return (
    
    <div className="loginMain">
      <img src={Logo} height="70px" width="100px" />
      <form className={classes.root} noValidate autoComplete="off">
        <TextField
          onChange={(e) => setusername(e.target.value)}
          id="filled-primary"
          label="Email"
          variant="filled"
          color="primary"
          value={username}
        />
        <FormControl className={clsx(classes.margin, classes.textField)} variant="filled" >
          <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
          <FilledInput
            id="filled-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={(e) => setpassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Button
          onClick={login}
          style={{ marginLeft: "17px" }}
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<CloudUploadIcon>send</CloudUploadIcon>}
        >
          Login
      </Button>
      </form>
    </div>

  );
}

export default Login;
