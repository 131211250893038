import React ,{useEffect} from "react";
import ReactGA from 'react-ga';
import logo from './logo.svg';
import './App.css';
import firebase from "./database"
import {server} from "./server"

import Routing from "./Routing"


function initializeReactGA() {
  ReactGA.initialize('G-R7N0T7XDQD');
  ReactGA.pageview('/HomePage');
}



function App() {
// Bmw200zka1818

  useEffect(()=>{
    initializeReactGA();
   
    // setTimeout(() => {
    //   localStorage.removeItem("adminerp")
    //   window.location.reload()
    // }, 6000);
 

  },[])

  return (
    <div >
      <div className="appMainDivMobile">
      <Routing />
      </div>
      <div style={{display:"none"}} className="appMainDivDesktop">Content is only available on small devices,mobiles or tablets</div>
    </div>
  );
}

export default App;
