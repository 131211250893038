export const initialState = {


  ladgertotal: [],

  
  }
  
  // Selector
  export const getBasketTotal = (basket) =>
    basket?.reduce((amount, item) => item.price + amount, 0)
  
  const reducer = (state, action) => {

    switch (action.type) {
      case 'TOTAL_LADGER_EXPENSE_AND_INCOME':
        return {
          ...state,
          ladgertotal: action.payload,
        }
  
      default:
        return state
    }
    
  }
  
  export default reducer
  