import React, { useEffect, useContext, useState } from "react";
import "./home.css"
import welcome from "../../assets/welcome.gif"
import { Admin } from "../../App"
import { useStateValue } from '../StateProvider'
import firebase from "../../database"

function Home(props) {

  const [{ ladgertotal }, dispatch] = useStateValue()
  const [bank, setbank] = useState("Daily Expense & Income")
  const [ladgertotallocal, setladgertotallocal] = useState("");
  const [totalBalance, setTotalBalance] = useState(0)

  const [TotalorderBalance, setTotalorderBalance] = useState(0)

  const [pendingreceivableandreceivable, setpendingreceivableandreceivable] = useState(0)


  useEffect(() => {

    const admin = sessionStorage.getItem("adminerp")
    if (!admin) {
      props.history.push('/')
    }

    var bankladgers = [];
    firebase.database().ref('bankladger/' + bank).once("value", snap => {
      var data = snap.val();
      for (let keys in data) {
        bankladgers.push({ ...data[keys], key: keys });
      }

      dispatch({
        type: 'TOTAL_LADGER_EXPENSE_AND_INCOME',
        payload: bankladgers,
      })
    })

    var lad = [];
    var lad2 = [];
    var balance = 0;
    firebase.database().ref('ladger').once("value", snap => {
      var data = snap.val();
      for (let keys in data) {
        lad.push(data[keys]);
      }
      lad.map((item, index) => {
        for (let keys2 in item) {
          lad2.push(item[keys2])
        }
      })
      if (lad2 && lad2.length) {
        Object.keys(data).map(item => {
          var lad = lad2.filter(d => d.showroom === item);
          balance += lad[lad.length - 1]['balance'];
        })
      }
      setTotalBalance(balance)

    })


    var ord = [];
    var ord2 = [];
    var balanceorders = 0;
    firebase.database().ref('orders').once("value", snap => {
      var data = snap.val();
      for (let keys in data) {
        ord.push(data[keys]);
      }
      ord.map((item, index) => {
        for (let keys2 in item) {
          ord2.push(item[keys2])
        }
      })
      if (ord2 && ord2.length) {
        Object.keys(data).map(item => {

          var ord = ord2.filter(d => d.showroom === item);
          // balanceorders += ord[0].total;

          ord.map(item2 => {
            balanceorders += item2.total;

          })
        })
      }
      setTotalorderBalance(balanceorders)
   
    })


  }, [])

  const cashinhand = () => {
    if (ladgertotal !== "") {
      setladgertotallocal(Number(ladgertotal[ladgertotal.length - 1].balance))
    }
  }


  return (
    <div style={{ height: "850px", display: "flex", justifyContent: "center" }}>
      <div style={{ marginTop: "100px" }}>
        {ladgertotal && ladgertotal !== '' && ladgertotal.length &&

          <h3 style={{ color: "green", fontWeight: "bold", marginTop: "20px", fontSize: "16px" }}> Amount in hand <br /> RS : {Number(ladgertotal[ladgertotal.length - 1].balance)}/=</h3>
        }
        {
          totalBalance && totalBalance !== '' && <h3 style={{ marginTop: "80px", color: "red", fontSize: "16px" }}>Receivable: <br /> <span style={{ color: "red", fontWeight: "bold" }}>RS : {totalBalance}/=</span></h3>
        }

        {
          TotalorderBalance && TotalorderBalance !== '' && <h4 style={{ marginTop: "80px", color: "red", fontSize: "16px" }}>pending orders Receivable: <br /> <span style={{ color: "red", fontWeight: "bold" }}>RS : {TotalorderBalance}/=</span></h4>
        }


      </div>
    </div>
  );
}

export default Home;
