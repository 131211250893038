import React, { useState ,useEffect} from "react";
import "./navbar.css"
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'

import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MenuIcon from '@material-ui/icons/Menu';
import BuildIcon from '@material-ui/icons/Build';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
// import Logo from "../../assets/logo.gif"
import Logo from "../../assets/logo.png"

import {server} from "../../server"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    brand: {
        marginRight: theme.spacing(2),
    },
    menuButton: {
        marginRight: theme.spacing(-3),
    },
    title: {
        flexGrow: 1,
    },
}));


function SwipeableTemporaryDrawer(props) {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [expand1Icon, setExpand1Icon] = useState(false);
    const [expand2Icon, setExpand2Icon] = useState(false);


    const classes = useStyles();

    const [state, setState] = React.useState({
        right: false,
    });


    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (open) {
            setDrawerOpen(true);
        }
        else {
            setDrawerOpen(false)
        }
        setState({ ...state, [anchor]: open });
    };

  

const logout =()=>{
    setDrawerOpen(false);
    setState({ ...state, right: false });
    sessionStorage.removeItem("adminerp")
    window.location.href = `${server}`;
}
const showroomsRoutes=(routes)=>{
    setDrawerOpen(false);
    setState({ ...state, right: false });
    setExpand1Icon(false)
    props.history.push(routes)
}

    const list = (anchor) => (
        <div
            role="presentation"
        // onClick={toggleDrawer('right', false)}
        // onKeyDown={toggleDrawer(anchor, false)}
        >
            <List style={{ padding: 10 }}>
               
                <ExpansionPanel style={{ marginTop: 10, boxShadow: "none" }} id="DrawerShoppingToolsMenu" onChange={(event, expanded) => { setExpand1Icon(expanded) }}>
                    <ExpansionPanelSummary
                        expandIcon={expand1Icon ? <RemoveIcon style={{ fontSize: 20, color: expand1Icon ? "red" : "" }} /> : <AddIcon style={{ fontSize: 20 }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <ListItemText><span style={{ fontWeight: "bold", fontSize: "12px", color: expand1Icon ? "red" : "" }}>SHOWROOM RECORDS</span></ListItemText>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails onClick={()=>showroomsRoutes('/showroom')}>
                        <ListItemText ><span style={{fontWeight:"bold",fontFamily:"italic",fontSize:"12px"}}>SHOWROOMS</span></ListItemText>
                    </ExpansionPanelDetails>
                    <ExpansionPanelDetails  onClick={()=>showroomsRoutes('/addshowroom')}>
                        <ListItemText ><span style={{fontWeight:"bold",fontFamily:"italic",fontSize:"12px"}}>ADD SHOWROOM</span></ListItemText>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                {/* <Divider /> */}
                <ExpansionPanel style={{ marginTop: 10, boxShadow: "none" }} id="DrawerShoppingToolsMenu" onChange={(event, expanded) => { setExpand2Icon(expanded) }}>
                    <ExpansionPanelSummary
                        expandIcon={expand2Icon ? <RemoveIcon style={{ fontSize: 20, color: expand2Icon ? "red" : "" }} /> : <AddIcon style={{ fontSize: 20 }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <ListItemText><span style={{ fontWeight: "bold", fontSize: "12px", color: expand2Icon ? "red" : "" }}>Daily Expense,Income & Vendors </span></ListItemText>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails onClick={()=>showroomsRoutes('/expenseAndIncome')}>
                        <ListItemText ><span style={{fontWeight:"bold",fontFamily:"italic",fontSize:"12px"}}>Daily Expense & Income</span></ListItemText>
                    </ExpansionPanelDetails>
                    {/* <ExpansionPanelDetails  onClick={()=>showroomsRoutes('/vendors')}>
                        <ListItemText ><span style={{fontWeight:"bold",fontFamily:"italic",fontSize:"12px"}}>Vendors</span></ListItemText>
                    </ExpansionPanelDetails>
                    <ExpansionPanelDetails  onClick={()=>showroomsRoutes('/addvendor')}>
                        <ListItemText ><span style={{fontWeight:"bold",fontFamily:"italic",fontSize:"12px"}}>Add Vendor</span></ListItemText>
                    </ExpansionPanelDetails> */}
                </ExpansionPanel>

                <Divider />
             
               <ListItem onClick={logout} button style={{ marginTop: 10 }}>
               <ListItemText><span style={{ fontWeight: "bold", fontSize: "12px" }}>LOGOUT</span></ListItemText>
               <ListItemIcon><ArrowForwardIosIcon style={{ fontSize: 20, marginLeft: "39px", fontSize: "17px" }} /></ListItemIcon>
           </ListItem>
             
              
                <Divider />
                
            </List>
        </div>
    );
    return (
        <div className="navbarMain">
            <AppBar
                fixed="top"
                style={{ backgroundColor: "#cacaca" }}
            >




                <div style={{ display: "flex", padding: "5px 0px 0px 30px" }}>
                    <div onClick={()=>showroomsRoutes('/Home')} style={{display:"flex",alignItems:"center"}}>
                        
                        <img src={Logo}  width="60%" />

                         </div>
                    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end",marginRight:"20px" }}>
                        <IconButton edge="start" style={{color:"#7d5739"}} >
                            {(!drawerOpen && (
                                <MenuIcon
                                    onClick={toggleDrawer("right", true)}
                                />
                            )) || (
                                    <CloseIcon
                                    />
                                )}
                        </IconButton>
                    </div>
                </div>



            </AppBar>
            {/* Mobile Navbar */}
            {["MENU"].map((anchor) => (
                <React.Fragment key={'right'}>
                    {!drawerOpen && <Button onClick={toggleDrawer('right', true)} style={{ color: "red", fontSize: "12px" }}> <MenuIcon /></Button>
                        ||
                        <Button onClick={toggleDrawer('right', false)} style={{ color: "red", fontSize: "12px", backgroundColor: "red", height: "100%" }}><CloseIcon /></Button>
                    }
                    <div style={{}}>
                        <SwipeableDrawer
                            anchor={'right'}
                            open={state['right']}
                            onClose={toggleDrawer('right', false)}
                            onOpen={toggleDrawer('right', true)}
                        >
                            {list(anchor)}
                        </SwipeableDrawer>
                    </div>
                </React.Fragment>


            ))}

        </div>
    );
}

export default SwipeableTemporaryDrawer;
