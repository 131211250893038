import React, { useEffect, useState } from "react";
import TextField from '@material-ui/core/TextField';
import DateTimePicker from 'react-datetime-picker';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import firebase from "../../database"
import CheckIcon from '@material-ui/icons/Check';
import { Navbar } from 'react-bootstrap'
import { server } from "../../server"
import "./showroom.css"
import { CSVLink, CSVDownload } from "react-csv";




const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  table: {
    minWidth: 700,
  },
}));


function Showroom(props) {
  const classes = useStyles();
  const [value, setvalue] = useState(1)
  const [showroom, setshowroom] = useState("Select Showroom")
  const [newshowroom, setnewshowroom] = useState([])
  const [detail, setdetail] = useState('')
  const [pc, setpc] = useState('')
  const [rate, setrate] = useState('')
  const [total, settotal] = useState('')
  const [debit, setdebit] = useState('')
  const [alertboxaction, setalertboxaction] = useState(false)
  const [item, setitem] = useState('')
  const [indextwo, setindextwo] = useState('')



  const [orderArray, setorderArray] = useState([])

  const [ladgerArray, setladgerArray] = useState([])

  const [orderbalancetotaltwo, setorderbalancetotaltwo] = useState('')


  useEffect(() => {

    const admin = sessionStorage.getItem("adminerp")
    if (!admin) {
      props.history.push('/')
    }


    var orders = [];
    firebase.database().ref('orders/' + showroom).once("value", snap => {
      var data = snap.val();
      for (let keys in data) {
        orders.push({ ...data[keys], key: keys });
      }
      setorderArray(orders)
      console.log(orders)
     var orderbalancetotal=0
      orders.map((itemtotal=>{
        setorderbalancetotaltwo(orderbalancetotal += itemtotal.total) 
      }))
    })





    var ladgers = [];
    firebase.database().ref('ladger/' + showroom).once("value", snap => {
      var data = snap.val();
      for (let keys in data) {
        ladgers.push({ ...data[keys], key: keys });
      }
      setladgerArray(ladgers)
    })



    var newshowrooms = [];
    firebase.database().ref('showrooms').once("value", snap => {
      var data = snap.val();
      for (let keys in data) {
        newshowrooms.push({ ...data[keys], key: keys });
      }
      setnewshowroom(newshowrooms)
    })

           

  }, [showroom])



  const totalFunc = (event) => {
    settotal(event.target.value)
  }


  const credit = () => {
    var data = {
      total: pc * rate,
      rate: rate,
      pc: pc,
      status: "https://thumbs.gfycat.com/AccurateImpressionableArawana-size_restricted.gif",
      detail: detail,
      showroom: showroom,
      date: new Date().toLocaleDateString('de-DE'),
    }

    if (showroom === "") {
      alert("select showroom first")
    }

    else if (showroom === "Select Showroom") {
      alert("select showroom first")
    }

    else if (detail === "") {
      alert("fill all fields")
    }
    else if (pc === "") {
      alert("fill all fields")
    }

    else if (rate === "") {
      alert("fill all fields")
    }

    else {
      firebase
        .database()
        .ref('orders/' + data.showroom)
        .push(data)
        .then(response => {
          alert("successfully Save")
          window.location.href = `${server}/showroom`;
        })
        .catch(error => {
          alert(error)
        });
    }
  }




  const debitAction = () => {

    if (showroom === "") {
      alert("select showroom first")
    }

    else if (showroom === "Select Showroom") {
      alert("select showroom first")
    }

    else if (detail === "") {
      alert("fill all fields")
    }

    else if (debit === "") {
      alert("fill all fields")
    }

    else {
      var debitData = {
        balance: "",
        PaymentRecieved: debit,
        total: null,
        rate: null,
        pc: null,
        detail: detail,
        showroom: showroom,
        date: new Date().toLocaleDateString('de-DE'),
      }

      if (ladgerArray.length === 0) {
        var c = -debitData.PaymentRecieved
        debitData.balance = c

      }

      else {
        var d = ladgerArray[ladgerArray.length - 1].balance - debitData.PaymentRecieved
        debitData.balance = d
      }


      firebase
        .database()
        .ref(`ladger/${showroom}`)
        .push(debitData)
        .then(response => {
          alert('successfully debit')
        })
        .catch(error => {
          alert(error)
        });
      window.location.href = `${server}/showroom`;
    }
  }












  const closealert = () => {
    setvalue(3)
    setalertboxaction(false)
  }

  const action = (value, i) => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    setalertboxaction(true)
    setvalue(0)
    setitem(value)
    setindextwo(i)
  }

  const actionalertbox = () => {
    var ladgerdata = {
      balance: '',
      total: item.total,
      rate: item.rate,
      pc: item.pc,
      detail: item.detail,
      showroom: item.showroom,
      date: new Date().toLocaleDateString('de-DE'),
    }

    if (ladgerArray.length === 0) {
      var c = item.total
      ladgerdata.balance = c

    }

    else {
      var c = ladgerArray[ladgerArray.length - 1].balance + + item.total
      ladgerdata.balance = c
    }



    firebase
      .database()
      .ref('ladger/' + showroom)
      .push(ladgerdata)
      .then(response => {
        alert("Successfully order done")
        setorderArray((old) => {
          return old.filter((arrElem, index) => {
            return index !== indextwo
          })
        })
        firebase
          .database()
          .ref(`orders/${showroom}/${item.key}`)
          .remove()
        window.location.href = `${server}/showroom`;

      })
      .catch(error => {
        alert(error)
      });
  }


  return (
    <div className="showroomMain">

      <Navbar fixed="top" style={{ marginTop: "13%", flexDirection: "column", backgroundColor: "#424242" }} >
        <div className="selectiondivsmain">
          <div onClick={() => setvalue(1)} style={{ borderBottom: value === 1 && "2px solid red", fontSize: "11px" }}>Place Order</div>
          <div onClick={() => setvalue(2)} style={{ borderBottom: value === 2 && "2px solid red", fontSize: "11px" }}>Payment Recieved</div>
          <div onClick={() => setvalue(3)} style={{ borderBottom: value === 3 && "2px solid red", fontSize: "11px" }}>Orders</div>
          <div onClick={() => setvalue(4)} style={{ borderBottom: value === 4 && "2px solid red", fontSize: "11px" }}>Ladger</div>
        </div>

        {value !== 0 &&

          <div className="selectlistMain">
            <div style={{ display: "flex", justifyContent: "center", color: "red", fontFamily: "italic", fontSize: "25px" }}>
              {showroom}
            </div>
            <select onChange={(event) => setshowroom(event.target.value)} className="selectlist">
              <option value="Select Showroom">Select Showroom</option>
              {newshowroom.map((item) => {
                return (
                  newshowroom &&
                  <option value={item.addshowroom}>{item.addshowroom}</option>
                )
              })}
            </select>
          </div>

        }


      </Navbar>

      {alertboxaction && value !== 1 && value !== 2 && value !== 4 &&
        <div style={{ position: "absolute", width: "100%", height: "500px", display: "flex", alignItems: "center", flexDirection: "column", marginTop: "60px" }}>
          <div style={{ width: "90%", backgroundColor: "black", borderRadius: "10px", }}>
            <div style={{ color: "white", fontSize: "20px", fontWeight: "bold", height: "70px", display: "flex", alignItems: "center", borderBottom: "2px solid white", justifyContent: "space-around" }}> <div>Order Delievery Confirmation</div> <div onClick={closealert} style={{ backgroundColor: "white", borderRadius: "50px", color: "black", width: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>X</div></div>
            <div style={{ color: "white", fontSize: "13px", paddingLeft: "30px", height: "70px", display: "flex", alignItems: "center", borderBottom: "2px solid white" }}>Are you sure this order have been delivered ?</div>
            <div style={{ color: "white", fontSize: "13px", height: "70px", display: "flex", alignItems: "center", borderBottom: "2px solid white", justifyContent: "flex-end", paddingRight: "30px" }}><button onClick={closealert} style={{ backgroundColor: "red", height: "40px", width: "60px", color: "white", marginRight: "10px", fontWeight: "bold" }}>Close</button> <button onClick={actionalertbox}  style={{ backgroundColor: "green", height: "40px", width: "60px", color: "white", marginRight: "10px", fontWeight: "bold" }}>OK</button></div>
          </div>
        </div>
      }



      {value === 1 &&
        <div className="creditMain">
          <div style={{ marginTop: "30px", display: "flex", justifyContent: "center" }}>
            <TextField
              onChange={(event) => setdetail(event.target.value)}
              style={{ width: "90%" }}
              id="filled-primary"
              label="Detail"
              variant="filled"
              color="primary"
            />
          </div>

          <div className="creditTextField3">
            <TextField
              onChange={(event) => setpc(event.target.value)}
              className="creditTextField31"
              id="filled-primary"
              label="Pc"
              variant="filled"
              color="primary"
              type="number"

            />
            <TextField
              onChange={(event) => setrate(event.target.value)}
              className="creditTextField32"
              id="filled-primary"
              label="Rate"
              variant="filled"
              color="primary"
              type="number"

            />
            <TextField
              onChange={(event) => settotal(event.target.value)}
              className="creditTextField33"
              id="filled-primary"
              label="Total"
              variant="filled"
              color="primary"
              value={pc * rate}
            />

          </div>

          <div className="saveButton">
            <Button
              onClick={credit}
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </div>

        </div>
      }

      {value === 2 &&
        <div className="debitMain">
          <div style={{ marginTop: "30px", display: "flex", justifyContent: "center" }}>
            <TextField
              onChange={(event) => setdetail(event.target.value)}
              style={{ width: "90%" }}
              id="filled-primary"
              label="Detail"
              variant="filled"
              color="primary"
            />

          </div>
          <div style={{ marginTop: "30px", display: "flex", justifyContent: "center" }}>
            <TextField
              onChange={(event) => setdebit(event.target.value)}
              style={{ width: "90%" }}
              id="filled-primary"
              label="Amount"
              variant="filled"
              color="primary"
              type="number"
            />

          </div>


          <div className="saveButton">
            <Button
              onClick={debitAction}
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </div>

        </div>
      }

      {value === 3 &&

      <div>
        
        <div style={{color:"red",marginTop:"200px",display:"flex",justifyContent:"center"}}>
       Total {orderbalancetotaltwo}
        </div>
      {orderArray.map((item, index) => {
          return (
            item.showroom === showroom && item.showroom !== "Select Showroom" &&
            <div className="OrdersMain" >
              <div style={{ fontSize: "30px", display: "flex", justifyContent: "center", width: "100%", margin: "10px 0px 20px 0px", color: "green", fontFamily: "italic", borderBottom: "3px dotted red", borderTop: "3px dotted red", padding: "7px", backgroundColor: "#9e9e9e" }}>Order Number {index + + 1}</div>
              <label style={{ margin: "0px 0px 0px 10px ", fontFamily: "italic" }}>Detail</label>
              <div className="ordersDetailMain">
                <div className="ordersDetail">{item.detail}</div>
              </div>

              <div className="pcRateTotal">

                <div className="pcRateTotalInnerMain">
                  <div style={{ fontFamily: "italic" }}>Pc</div>
                  <div className="pcRateTotalInner">{item.pc}</div>
                </div>

                <div className="pcRateTotalInnerMain">
                  <div style={{ fontFamily: "italic" }}>Rate</div>
                  <div className="pcRateTotalInner">{item.rate}</div>
                </div>

                <div className="pcRateTotalInnerMain">
                  <div style={{ fontFamily: "italic" }}>Total</div>
                  <div className="pcRateTotalInner">{item.total}</div>
                </div>

              </div>


              <div className="dateStatusAction">

                <div className="dateStatusActionInnerMain">
                  <div style={{ fontFamily: "italic" }}>Date</div>
                  <div className="dateStatusActionInner">{item.date}</div>
                </div>

                <div className="dateStatusActionInnerMain">
                  <div style={{ fontFamily: "italic" }}>Status</div>
                  <div className="dateStatusActionInner" style={{ color: "red", flexDirection: "column", fontSize: "12px" }}><img src={item.status} height="28px" width="28px" />pending</div>
                </div>

                <div className="dateStatusActionInnerMain" onClick={() => action(item, index)}>
                  <div style={{ fontFamily: "italic" }}>Action</div>
                  <div className="dateStatusActionInner"><CheckIcon style={{ color: "green" }} /></div>
                </div>

              </div>

            </div>

          )
        })}
        </div>
  



      }

      {value === 4 &&

        <div>
          <div style={{ marginTop: "200px", display: "flex", justifyContent: "center", width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "center", backgroundColor: "grey", width: "50%", padding: "10px", borderRadius: "10px" }}>
              <CSVLink style={{ color: "black", fontWeight: "bold" }} data={ladgerArray}>Download Ladger</CSVLink>
            </div>
          </div>
          {ladgerArray.map((item, i) => {
            return (
              item.showroom === showroom && item.showroom !== "Select Showroom" &&
              <div className="ladgerMain">
                <div >
                  <div className="detail">Detail </div>
                  <div className="detailinner">{item.detail}</div>
                </div>

                <div className="ladgerRow2">

                  <div>
                    <div className="ladgerRow2Titles">Date </div>
                    <div className="ladgerRow2detail">{item.date}</div>
                  </div>

                  <div>
                    <div className="ladgerRow2Titles">Pc </div>
                    <div className="ladgerRow2detail">{item.pc}</div>
                  </div>

                  <div>
                    <div className="ladgerRow2Titles">Rate</div>
                    <div className="ladgerRow2detail">{item.rate}</div>
                  </div>

                  <div>
                    <div className="ladgerRow2Titles">Total</div>
                    <div className="ladgerRow2detail">{item.total}</div>
                  </div>


                  <div>
                    <div className="ladgerRow2Titles">Cash Recieved</div>
                    <div className="ladgerRow2detail">{item.PaymentRecieved}</div>
                  </div>

                  <div>
                    <div className="ladgerRow2Titles">Balance</div>
                    <div className="ladgerRow2detail">{item.balance}</div>
                  </div>
                </div>

              </div>

            )
          })}




        </div>




      }


    </div>
  );
}

export default Showroom;
