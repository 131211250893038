import React, { useEffect, useState } from "react";
import TextField from '@material-ui/core/TextField';
import DateTimePicker from 'react-datetime-picker';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import firebase from "../../database"
import CheckIcon from '@material-ui/icons/Check';
import { Navbar } from 'react-bootstrap'
import { server } from "../../server"
import { CSVLink, CSVDownload } from "react-csv";
import { useStateValue } from '../StateProvider'




const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  table: {
    minWidth: 700,
  },
}));


function Bank(props) {

  const [{ allladger }, dispatch] = useStateValue()

  const classes = useStyles();
  const [value, setvalue] = useState(1)
  const [bank, setbank] = useState("Daily Expense & Income")
  const [newbank, setnewbank] = useState([])
  const [bankdetail, setbankdetail] = useState('')
  const [BankIncomePayment, setBankIncomePayment] = useState('')
  const [BankExpensePayment, setBankExpensePayment] = useState('')

  const [bankladgerArray, setbankladgerArray] = useState([])

  const [alertboxincome, setalertboxincome] = useState(false)
  const [alertboxexpense, setalertboxexpense] = useState(false)




  useEffect(() => {

    const admin = sessionStorage.getItem("adminerp")
    if (!admin) {
      props.history.push('/')
    }




    var bankladgers = [];
    firebase.database().ref('bankladger/' + bank).once("value", snap => {
      var data = snap.val();
      for (let keys in data) {
        bankladgers.push({ ...data[keys], key: keys });
      }
      setbankladgerArray(bankladgers)
      dispatch({
        type: 'TOTAL_LADGER_EXPENSE_AND_INCOME',
        payload: bankladgers,
      })
    })

  


    



  }, [])






const closealertincome = () => {
  setalertboxincome(false)
  setvalue(1)
  window.location.href = `${server}/expenseAndIncome`;

}

const Okincome = () => {

  var ladgerbankdata = {
    balance: "",
    income: BankIncomePayment,
    detail: bankdetail,
    DailyExpenseAndIncome: bank,
    date: new Date().toLocaleDateString('de-DE'),
  }

  if (bankdetail === "") {
    alert("fill all fields")
  }

  else if(BankIncomePayment === ""){
    alert("fill all fields")
  }

  else {

    if (bankladgerArray.length === 0) {
      ladgerbankdata.balance = BankIncomePayment
    }
    else {
      var c = Number(bankladgerArray[bankladgerArray.length - 1].balance) + Number(ladgerbankdata.income)
      ladgerbankdata.balance = c
    }
    firebase
      .database()
      .ref('bankladger/' + bank)
      .push(ladgerbankdata)
      .then(response => {
        alert("Successfully add in ladger")
        window.location.href = `${server}/expenseAndIncome`;
      })
      .catch(error => {
        alert(error)
      });

  }

}

  const incomeAction = () => {
    if (bankdetail === "") {
      alert("fill all fields")
    }
  
    else if(BankIncomePayment === ""){
      alert("fill all fields")
    }
    else{
      setalertboxincome(true)
      setvalue(0)
    }
  }












const closealertexpense= () =>{
  setvalue(2)
  setbankdetail("")
  setBankExpensePayment("")
  setalertboxexpense(false)

}


    const Okexpense = ()=>{

      var ladgerExpensebankdata = {


        balance: "",
        expense: BankExpensePayment,
        detail: bankdetail,
        DailyExpenseAndIncome: bank,
        date: new Date().toLocaleDateString('de-DE'),
      }
  
      if (bankdetail === "") {
        alert("fill all fields")
      }
  
      else if (BankExpensePayment === "") {
        alert("fill all fields")
      }
  
  
      else {
  
        if (bankladgerArray.length === 0) {
          ladgerExpensebankdata.balance = BankExpensePayment
        }
        else {
          var c = Number(bankladgerArray[bankladgerArray.length - 1].balance) - Number(ladgerExpensebankdata.expense)
          ladgerExpensebankdata.balance = c
        }
  
        firebase
          .database()
          .ref('bankladger/' + bank)
          .push(ladgerExpensebankdata)
          .then(response => {
            alert("Successfully add in ladger")
            window.location.href = `${server}/expenseAndIncome`;
          })
          .catch(error => {
            alert(error)
          });
  
      }

    }


  const expenseAction = () => {
       if (bankdetail === "") {
      alert("fill all fields")
    }

    else if (BankExpensePayment === "") {
      alert("fill all fields")
    }
    else{
      setalertboxexpense(true)
      setvalue(0)
    }
  }






  return (
    <div className="showroomMain">

      {alertboxincome && value !== 2 && value !== 3 && 
        <div style={{ position: "absolute", width: "100%",height:"500px", display: "flex",alignItems: "center", flexDirection: "column",marginTop:"60px"}}>
          <div style={{ width: "90%", backgroundColor: "black",borderRadius:"10px",}}>
            <div style={{color:"white",fontSize:"20px",fontWeight:"bold",height:"70px",display:"flex",alignItems:"center",borderBottom:"2px solid white",justifyContent:"space-around"}}> <div>Income Confirmation</div> <div onClick={closealertincome} style={{backgroundColor:"white",borderRadius:"50px",color:"black",width:"30px",display:"flex",justifyContent:"center",alignItems:"center"}}>X</div></div>
            <div style={{color:"white",fontSize:"13px",paddingLeft:"30px",height:"70px",display:"flex",alignItems:"center",borderBottom:"2px solid white"}}>Are you sure you add data in income ?</div>
            <div style={{color:"white",fontSize:"13px",height:"70px",display:"flex",alignItems:"center",borderBottom:"2px solid white",justifyContent:"flex-end",paddingRight:"30px"}}><button onClick={closealertincome} style={{backgroundColor:"red",height:"40px",width:"60px",color:"white",marginRight:"10px",fontWeight:"bold"}}>Close</button> <button onClick={Okincome} style={{backgroundColor:"green",height:"40px",width:"60px",color:"white",marginRight:"10px",fontWeight:"bold"}}>OK</button></div>
          </div>  
        </div>
      }

      {alertboxexpense && value !== 1 && value !== 3 && 
        <div style={{ position: "absolute", width: "100%",height:"500px", display: "flex",alignItems: "center", flexDirection: "column",marginTop:"60px"}}>
        <div style={{ width: "90%", backgroundColor: "black",borderRadius:"10px",}}>
          <div style={{color:"white",fontSize:"20px",fontWeight:"bold",height:"70px",display:"flex",alignItems:"center",borderBottom:"2px solid white",justifyContent:"space-around"}}> <div>Expense Confirmation</div> <div onClick={closealertexpense} style={{backgroundColor:"white",borderRadius:"50px",color:"black",width:"30px",display:"flex",justifyContent:"center",alignItems:"center"}}>X</div></div>
          <div style={{color:"white",fontSize:"13px",paddingLeft:"30px",height:"70px",display:"flex",alignItems:"center",borderBottom:"2px solid white"}}>Are you sure you add data in expense ?</div>
          <div style={{color:"white",fontSize:"13px",height:"70px",display:"flex",alignItems:"center",borderBottom:"2px solid white",justifyContent:"flex-end",paddingRight:"30px"}}><button onClick={closealertexpense} style={{backgroundColor:"red",height:"40px",width:"60px",color:"white",marginRight:"10px",fontWeight:"bold"}}>Close</button> <button onClick={Okexpense} style={{backgroundColor:"green",height:"40px",width:"60px",color:"white",marginRight:"10px",fontWeight:"bold"}}>OK</button></div> 
        </div>  
      </div>
      }

      <Navbar fixed="top" style={{ marginTop: "13%", flexDirection: "column", backgroundColor: "#424242" }} >
        <div className="selectiondivsmain">
          <div onClick={() => setvalue(1)} style={{ borderBottom: value === 1 && "2px solid red" }}>Income</div>
          <div onClick={() => setvalue(2)} style={{ borderBottom: value === 2 && "2px solid red" }}>Expense</div>
          <div onClick={() => setvalue(3)} style={{ borderBottom: value === 3 && "2px solid red", }}>Ladger</div>
        </div>

        {value !== 0 &&

          <div className="selectlistMain">
            <div style={{ display: "flex", justifyContent: "center", color: "red", fontFamily: "italic", fontSize: "18px" }}>
              {bank}
            </div>
            {/* <select onChange={(event) => setbank(event.target.value)} className="selectlist">
              {newbank.map((item) => {
                return (
                  newbank &&
                  <option value={item.addbank}>{item.addbank}</option>
                )
              })}
            </select> */}
          </div>

        }
      </Navbar>




      {value === 1 && alertboxincome === false &&
        <div className="debitMain">
          <div style={{ marginTop: "30px", display: "flex", justifyContent: "center" }}>
            <TextField
              onChange={(event) => setbankdetail(event.target.value)}
              style={{ width: "90%" }}
              id="filled-primary"
              label="Detail"
              variant="filled"
              color="primary"
            />

          </div>
          <div style={{ marginTop: "30px", display: "flex", justifyContent: "center" }}>
            <TextField
              onChange={(event) => setBankIncomePayment(event.target.value)}
              style={{ width: "90%" }}
              id="filled-primary"
              label="Enter Payment"
              variant="filled"
              color="primary"
              type="number"
            />

          </div>


          <div className="saveButton">
            <Button
              onClick={incomeAction}
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              startIcon={<SaveIcon />}
              disabled={alertboxincome === true}
            >
              Save
            </Button>
          </div>

        </div>
      }

      {value === 2 &&  alertboxexpense === false &&
        <div className="debitMain">
          <div style={{ marginTop: "30px", display: "flex", justifyContent: "center" }}>
            <TextField
              onChange={(event) => setbankdetail(event.target.value)}
              style={{ width: "90%" }}
              id="filled-primary"
              label="Detail"
              variant="filled"
              color="primary"
            />

          </div>
          <div style={{ marginTop: "30px", display: "flex", justifyContent: "center" }}>
            <TextField
              onChange={(event) => setBankExpensePayment(event.target.value)}
              style={{ width: "90%" }}
              id="filled-primary"
              label="Enter Payment"
              variant="filled"
              color="primary"
              type="number"
            />

          </div>


          <div className="saveButton">
            <Button
              onClick={expenseAction}
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              startIcon={<SaveIcon />}
              disabled={alertboxexpense === true}

            >
              Save
            </Button>
          </div>

        </div>
      }



      {value === 3 &&
     
        <div>
          <div style={{ marginTop: "200px", display: "flex", justifyContent: "center", width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "center", backgroundColor: "grey", width: "50%", padding: "10px", borderRadius: "10px" }}>
              <CSVLink style={{ color: "black", fontWeight: "bold" }} data={bankladgerArray}>Download Ladger</CSVLink>
            </div>
          </div>
          {bankladgerArray.map((item, i) => {
            return (
              // item.bank === bank && item.bank !== "Daily Expense & Income" &&
              <div className="ladgerMain">
                <div >
                  <div className="detail">Detail </div>
                  <div className="detailinner">{item.detail}</div>
                </div>

                <div className="ladgerRow2">

                  <div>
                    <div className="ladgerRow2Titles">Date </div>
                    <div className="ladgerRow2detail">{item.date}</div>
                  </div>

                  <div>
                    <div className="ladgerRow2Titles">Income </div>
                    <div className="ladgerRow2detail">{item.income}</div>
                  </div>

                  <div>
                    <div className="ladgerRow2Titles">Expense</div>
                    <div className="ladgerRow2detail">{item.expense}</div>
                  </div>

                  <div>
                    <div className="ladgerRow2Titles">Balance</div>
                    <div className="ladgerRow2detail">{item.balance}</div>
                  </div>
                </div>
              </div>
            )
          })
          }
        </div>
      }


    </div>
  );
}

export default Bank;
